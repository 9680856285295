<template>
  <base-puzzle username="Pazzle" :challenges="challenges" @continue="$router.push({name: 'play/season-1/level-2'})">
    <template #header>
      <b-card-title>
        Create an account!
      </b-card-title>
      <p>Welcome to Pazzle. First things first, let's make you an account</p>
    </template>

    <template #fields="{done}">
      <auto-field value="Pazzle" @done="done($event)">
        <template #description>Pick a username</template>
        <template #feedback="{value}">{{ value }} is available</template>
      </auto-field>
    </template>

    <template #continue>
      Sign up!
    </template>
  </base-puzzle>
</template>

<script>
import BasePuzzle from "@/components/BasePuzzle";
import AutoField from "@/components/AutoField";

export default {
  components: {AutoField, BasePuzzle},
  data() {
    return {
      challenges: [
        {text: `Must contain at least one lowercase letter`, test: /[a-z]/},
        {text: `Must be at exactly 8 characters long`, test: /.{8}/},
        {text: `Must not contain 'password'`, test: /^(?!password).*$/i},
        {text: `Must contain at least one number `, test: /[0-9]/},
      ]
    }
  }
}
</script>
